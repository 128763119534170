import React from "react"
import { graphql } from "gatsby"

import { Flexible as Template } from "../components/Flexible/Flexible"

export const query = graphql`
  query($id: String!) {
    page: sanityPageFlexible(_id: { eq: $id }) {
      title
      slug {
        current
      }
      hide
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content {
        ... on SanitySectionProductsCarousel {
          ...PAGE_SECTION_PRODUCTS_CAROUSEL
        }
        ... on SanitySectionFeaturedContentCarousel {
          ...PAGE_SECTION_FEATURED_CONTENT_CAROUSEL
        }
        ... on SanitySectionImageGrid {
          ...PAGE_SECTION_IMAGE_GRID
        }
        ... on SanitySectionOkendoReferral {
          _type
          title
        }
        ... on SanitySectionHero {
          ...SANITY_SECTION_HERO
        }
        ... on SanitySectionImageText {
          ...PAGE_SECTION_IMAGE_TEXT
        }
        ... on SanitySectionRichText {
          ...PAGE_SECTION_RICH_TEXT
        }
        ... on SanitySectionInstagram {
          ...PAGE_SECTION_INSTAGRAM
        }
        ... on SanitySectionImageContentCarousel {
          ...PAGE_SECTION_IMAGE_CONTENT_CAROUSEL
        }
        ... on SanitySectionEmarsysForm {
          ...PAGE_SECTION_EMARSYS_FORM
        }
        ... on SanitySectionRedPepperCatalogueEmbed {
          _type
          catalogueId
          settings {
            ...PAGE_SECTION_SETTINGS
          }
        }
      }
    }
  }
`

const Component = ({ data, ...props }) => <Template {...props} {...data} />
export default Component
